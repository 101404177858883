<template>
  <div>
    <v-container class="mt-5">
      <v-row dense no-gutters>
        <v-col class="text-center">
          Come here, boy!
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    name: 'Logout.vue',
    data() {
      return {
      }
    },
    created(){
      let self = this;
      this.userLogout()
        .then(() => {
          self.systemMessage('Logged out successfully!');
          setTimeout(() => {
            self.$router.push({ name: 'Login' });
            // document.location.href = '/login'
          },1000)
        })
        .catch(() => {
          self.systemMessage('Error occurred. Try again!')
        })

    },
    methods:{
      ...mapActions({
          'userLogout':'users/logout',
      }),
    }
  }
</script>

<style lang="scss">
</style>